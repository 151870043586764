import { fadeInUp } from "@/lib/animations";
import { actions } from "astro:actions";
import { AnimatePresence, motion } from "framer-motion";
import { CheckCircle, ChevronDown, Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import AlertError from "./alert-error";
import { Card, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import GradualSpacing from "@/components/gradual-spacing";

interface Country {
  name: string;
  code: string;
  flag: string;
  dialCode: string;
}

const countries: Country[] = [
  { name: "Kenya", code: "KE", flag: "🇰🇪", dialCode: "+254" },
  { name: "Burundi", code: "BI", flag: "🇧🇮", dialCode: "+257" },
  { name: "Comoros", code: "KM", flag: "🇰🇲", dialCode: "+269" },
  { name: "Djibouti", code: "DJ", flag: "🇩🇯", dialCode: "+253" },
  { name: "Eritrea", code: "ER", flag: "🇪🇷", dialCode: "+291" },
  { name: "Ethiopia", code: "ET", flag: "🇪🇹", dialCode: "+251" },
  { name: "Madagascar", code: "MG", flag: "🇲🇬", dialCode: "+261" },
  { name: "Malawi", code: "MW", flag: "🇲🇼", dialCode: "+265" },
  { name: "Mozambique", code: "MZ", flag: "🇲🇿", dialCode: "+258" },
  { name: "Rwanda", code: "RW", flag: "🇷🇼", dialCode: "+250" },
  { name: "Somalia", code: "SO", flag: "🇸🇴", dialCode: "+252" },
  { name: "South Sudan", code: "SS", flag: "🇸🇸", dialCode: "+211" },
  { name: "Tanzania", code: "TZ", flag: "🇹🇿", dialCode: "+255" },
  { name: "Uganda", code: "UG", flag: "🇺🇬", dialCode: "+256" },
  { name: "Zambia", code: "ZM", flag: "🇿🇲", dialCode: "+260" },
  { name: "Zimbabwe", code: "ZW", flag: "🇿🇼", dialCode: "+263" },
];

interface Props {}

const NewsletterSubscribeForm = ({}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [names, setNames] = useState<string | undefined>();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please provide your email");
      return;
    }

    if (!phone) {
      toast.error("Please provide a phone number we can reach you");
      return;
    }

    if (!names) {
      toast.error("Please provide your shop's name");
      return;
    }

    setIsLoading(true);
    setError(null);

    const { data, error } = await actions.frontend.subscribeToNewsletter({
      email,
      phone,
      names,
      role: "SHOP_MANAGER",
    });

    setIsLoading(false);

    if (error) {
      setError(error.message);
      return;
    }

    setIsSubscribed(true);
    toast.success(data.message);
  };

  return (
    <>
      {!isSubscribed ? (
        <motion.form
          onSubmit={handleSubmit}
          className="max-w-md mx-auto"
          {...fadeInUp}
          transition={{ ...fadeInUp.transition, delay: 0.6 }}>
          <GradualSpacing
            text="Join the Waitlist"
            className="text-2xl font-semibold mb-4 text-center"
            duration={0.5}
            delayMultiple={0.04}
            framerProps={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
          />
          <div className="flex flex-col space-y-4">
            <motion.input
              type="text"
              placeholder="Shop Name"
              className="px-4 py-3 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
              whileFocus={{ scale: 1.02 }}
              value={names}
              onChange={(e) => setNames(e.target.value)}
            />

            <div className="flex">
              <CountrySelector
                selectedCountry={selectedCountry}
                onSelect={setSelectedCountry}
              />
              <motion.input
                type="tel"
                placeholder="Phone Number"
                className="flex-1 px-4 py-3 text-gray-900 placeholder-gray-500 bg-white border border-l-0 border-gray-300 rounded-r-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                whileFocus={{ scale: 1.02 }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <motion.input
              id="email"
              type="email"
              placeholder="Enter Your Email Address"
              className="px-4 py-3 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
              whileFocus={{ scale: 1.02 }}
              onChange={(e) => setEmail(e.target.value)}
            />

            <motion.div
              className="flex items-center justify-center"
              {...fadeInUp}
              transition={{ ...fadeInUp.transition, delay: 0.7 }}>
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="terms"
                    className="sr-only"
                    checked={acceptTerms}
                    required
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                  <div
                    className={`w-6 h-6 border-2 rounded-md ${
                      acceptTerms
                        ? "bg-[#65462A] border-[#65462A]"
                        : "border-gray-300"
                    } transition-colors duration-200`}>
                    {acceptTerms && (
                      <svg
                        className="w-4 h-4 text-white fill-current"
                        viewBox="0 0 20 20">
                        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                      </svg>
                    )}
                  </div>
                </div>
                <span className="ml-2 text-sm text-gray-600">
                  I accept the{" "}
                  <a
                    href="/terms-of-service"
                    className="text-primary hover:underline">
                    Terms and Conditions
                  </a>
                </span>
              </label>
            </motion.div>

            {error && <AlertError message={error} />}

            <motion.button
              type="submit"
              className={`px-6 py-3 text-base font-semibold text-white transition-all duration-200 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-md ${
                acceptTerms
                  ? "bg-primary hover:bg-primary-dark"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              whileHover={acceptTerms ? { scale: 1.05 } : {}}
              whileTap={acceptTerms ? { scale: 0.95 } : {}}
              disabled={!acceptTerms || isLoading}>
              Join The Waitlist
              {isLoading && (
                <Loader2 className="w-4 h-4 ml-2 animate-spin inline-block" />
              )}
            </motion.button>
          </div>

          {error && !acceptTerms && (
            <motion.div
              className="mt-2 text-red-500 text-sm text-center"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}>
              Must accept terms and conditions to proceed
            </motion.div>
          )}
        </motion.form>
      ) : (
        <Card className="mt-10 border-success bg-success/20">
          <CardContent className="p-4 flex gap-4 items-center">
            <div>
              <CheckCircle className="h-10 w-10 text-success" />
            </div>

            <div>
              <h2 className="text-2xl font-semibold">
                Subscribed to Newsletter
              </h2>
              <p>
                You&apos;ve successfully subscribed to our newsletter.
                <br /> Check your email for confirmation.
              </p>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

function CountrySelector({
  selectedCountry,
  onSelect,
}: {
  selectedCountry: Country;
  onSelect: (country: Country) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    window.scrollTo(0, 0);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <motion.button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-3 py-3 bg-gray-100 rounded-l-full border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}>
        <span className="text-xl">{selectedCountry.flag}</span>
        <ChevronDown
          className={`w-4 h-4 text-gray-500 transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute z-50 w-64 mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
            <div className="max-h-60 overflow-auto py-1">
              {countries.map((country) => (
                <motion.button
                  key={country.code}
                  type="button"
                  onClick={() => {
                    onSelect(country);
                    setIsOpen(false);
                  }}
                  className={`w-full px-4 py-2 text-left flex items-center gap-3 hover:bg-gray-50 ${
                    selectedCountry.code === country.code ? "bg-gray-50" : ""
                  }`}
                  whileHover={{ backgroundColor: "rgba(0,0,0,0.05)" }}>
                  <span className="text-xl">{country.flag}</span>
                  <div className="flex-1">
                    <span className="text-gray-900">{country.name}</span>
                    <span className="text-gray-500 ml-2">
                      {country.dialCode}
                    </span>
                  </div>
                </motion.button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default NewsletterSubscribeForm;
