import AnimatedGridPattern from "@/components/animated-grid-pattern";
import { type SiteInfoType } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import NewsletterSubscribeForm from "./newsletter-subscribe-form";
import ShineBorder from "@/components/shine-border";
import { fadeInUp, scaleUp } from "@/lib/animations";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

interface Props {
  site: SiteInfoType;
}

const ComingSoon = ({ site }: Props) => {
  return (
    <div className="relative min-h-screen flex flex-col bg-white overflow-hidden">
      <AnimatedGridPattern
        numSquares={70}
        maxOpacity={0.6}
        duration={3}
        repeatDelay={1}
        className={cn(
          "[mask-image:radial-gradient(1000px_circle_at_center,white,transparent)]",
          "absolute inset-0 z-0"
        )}
        colorClass="bg-accent"
      />
      <motion.header className="py-4 md:py-6 relative z-10" {...fadeInUp}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
          <div className="flex items-center justify-center sm:justify-between">
            <div className="flex-shrink-0">
              <motion.a
                href="/"
                title={site.name}
                className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}>
                <img
                  className="w-auto h-16"
                  src={site.logo}
                  alt={site.name}
                  loading="lazy"
                />
              </motion.a>
            </div>
          </div>
        </div>
      </motion.header>

      <main className="flex-grow pt-8 pb-8 sm:pb-12 lg:pt-6 relative z-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl relative">
          <div className="text-center mb-12">
            <motion.h1
              className="text-4xl font-bold leading-tight text-gray-900 sm:text-5xl lg:text-6xl font-pj mb-4"
              {...scaleUp}>
              Elevate Your Shop with{" "}
              <span className="text-primary">Dukaloco</span>
            </motion.h1>
            <motion.p
              className="text-xl text-gray-600 font-inter max-w-2xl mx-auto"
              {...fadeInUp}
              transition={{ ...fadeInUp.transition, delay: 0.4 }}>
              Join the waitlist for the ultimate e-commerce platform designed
              for ambitious business owners like you.
            </motion.p>
          </div>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12"
            {...fadeInUp}
            transition={{ ...fadeInUp.transition, delay: 0.5 }}>
            {[
              {
                title: "1. Digitize Your Shop",
                description:
                  "Easily create and customize your online store with our intuitive tools.",
                image: "/img/digitize-shop.jpg",
                alt: "Build Your Shop",
              },
              {
                title: "2. Showcase Your Products",
                description:
                  "Display your products beautifully and reach a wider audience.",
                image: "/img/showcase-products.jpg",
                alt: "Showcase Your Products",
              },
              {
                title: "3. Grow Your Business",
                description:
                  "Leverage our tools and community to scale your business effectively.",
                image: "/img/grow-business.jpg",
                alt: "Grow Your Business",
              },
            ].map((card, index) => (
              <ShineBorder
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
                color={"#f59e0b"}>
                <div className="relative w-full pt-[56.25%]">
                  <img
                    src={card.image}
                    alt={card.alt}
                    className="absolute inset-0 w-full h-full object-cover"
                    loading="lazy"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
                  <p className="text-primary">{card.description}</p>
                </div>
              </ShineBorder>
            ))}
          </motion.div>

          <motion.div
            className="max-w-md mx-auto"
            {...fadeInUp}
            transition={{ ...fadeInUp.transition, delay: 0.6 }}>
            <NewsletterSubscribeForm />
          </motion.div>
        </div>
      </main>

      <motion.footer
        className="py-8 mt-8 relative z-10"
        {...fadeInUp}
        transition={{ ...fadeInUp.transition, delay: 0.7 }}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
          <div className="flex flex-col items-center space-y-4">
            <div className="flex space-x-4">
              <motion.a
                href="https://www.instagram.com/dukaloco/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark transition-colors duration-200"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}>
                <FaInstagram size={24} />
                <span className="sr-only">Instagram</span>
              </motion.a>
              <motion.a
                href="https://www.facebook.com/profile.php?id=100087280923328"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark transition-colors duration-200"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}>
                <FaFacebook size={24} />
                <span className="sr-only">Facebook</span>
              </motion.a>
              <motion.a
                href="https://x.com/dukaloco"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark transition-colors duration-200"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}>
                <FaTwitter size={24} />
                <span className="sr-only">X/Twitter</span>
              </motion.a>
            </div>
            <p className="text-sm text-primary text-center">
              ©{new Date().getFullYear()} {site.name}
              {" | "}
              <motion.span whileHover={{ scale: 1.05 }}>
                <a
                  href="/privacy-policy"
                  className="underline hover:text-primary-dark transition-colors duration-200">
                  Privacy Policy
                </a>
              </motion.span>
              {" | "}
              <motion.span whileHover={{ scale: 1.05 }}>
                <a
                  href="/terms-of-service"
                  className="underline hover:text-primary-dark transition-colors duration-200">
                  Terms of Service
                </a>
              </motion.span>
            </p>
          </div>
        </div>
      </motion.footer>
    </div>
  );
};

export default ComingSoon;
