// animations.ts
export const fadeInUp = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const fadeInDown = {
  initial: { opacity: 0, y: -50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const fadeInLeft = {
  initial: { opacity: 0, x: -50 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, delay: 0.2 },
};

export const fadeInRight = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, delay: 0.2 },
};

export const scaleUp = {
  initial: { scale: 0.8 },
  animate: { scale: 1 },
  transition: { duration: 0.5, delay: 0.4 },
};

export const floatAnimation = {
  yellowCircle: {
    animate: {
      y: [0, -20, 0],
      rotate: [0, 360],
    },
    transition: {
      duration: 5,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
  blueCircle: {
    animate: {
      y: [0, 20, 0],
      rotate: [0, -360],
    },
    transition: {
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};
